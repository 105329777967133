import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter, routerMiddleware } from 'react-router-redux'
import createHistory from "history/createBrowserHistory"
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import reducers from './reducers'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import { Home, GlobalNavigation, TopicsRouting, GoodsRouting, RuleRouting, PlayRouting, Footer } from './containers'
import './index.css'
import * as actions from './actions'
import { LANGUAGE_SHORT_CODE } from './constants';

const history = createHistory()

const middleware = [ thunk ]
middleware.push(routerMiddleware(history))
if (process.env.NODE_ENV !== 'production' || window.location.port === '3000') {
  middleware.push(createLogger())
}

const store = createStore(
  reducers,
  applyMiddleware(...middleware)
)

const customTheme = createMuiTheme({
  palette: {
    primary: { main: '#519d38' },
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  footer: {
    marginTop: 'auto'
  }
})

class App extends React.Component {
  resize() {
    store.dispatch(actions.window.resize(window.innerWidth, window.innerHeight))
  }

  componentWillMount() {
    store.dispatch(actions.window.resize(window.innerWidth, window.innerHeight))
    window.addEventListener('resize', this.resize, true )
    window.addEventListener('orientationchange', this.resize, true)
  }
  
  render() {
    const { classes } = this.props
    
    var regstr = Object.keys(LANGUAGE_SHORT_CODE).map(key => LANGUAGE_SHORT_CODE[key]).join('|')
    return (
      <div className={classes.wrapper}>
        <div>
          <GlobalNavigation>
            <Switch>
              <Route exact path={`/play/:language(${regstr})`} component={PlayRouting}/>
              {/* <Route path={`/:language(${regstr})/topics`} component={TopicsRouting}/>
              <Route path={`/:language(${regstr})/goods`} component={GoodsRouting}/>
              <Route path={`/:language(${regstr})/rule`} component={RuleRouting}/> */}
              {/* <Route exact path="/" component={Home}/> */}
              {/* <Route path="/topics" component={TopicsRouting}/>
              <Route path="/goods" component={GoodsRouting}/>
              <Route path="/rule" component={RuleRouting}/> */}
              <Route path="/play" component={PlayRouting}/>
            </Switch>
          </GlobalNavigation>
        </div>
      </div>
    )
  }
}

App = withStyles(styles, { withTheme: true })(App)

render(
  <Provider store={store}>
    <ConnectedRouter history={history} store={store}>
      <MuiThemeProvider theme={customTheme}>
        <App/>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)
