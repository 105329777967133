import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { PlayPage, VSPage, PuzzlePage, NyankoPuzzlePage, NyankoPuzzleScoreCard, FreeBoardPage, UserCard } from '../../containers'
import { LANGUAGE_SHORT_CODE } from '../../constants';
import { Grid } from '@material-ui/core';

const mapStateToProps = (state, ownProps) => {
  const { width, height } = state.window
  return ({
    width,
    height,
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
  })
}

const styles = theme => ({
  root: {
		backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing.unit * 8,
  },
  gridContainer: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  content: {
    margin: '0 auto',
    padding: theme.spacing.unit,
    maxWidth: 700,
  },
  sideContent: {
    padding: theme.spacing.unit,
  }
})


class PlayRouting extends React.Component {
  render() {
    const { classes, theme, height } = this.props
    const styles = {
      root: {
        minHeight: height,
      },
      content: {
        minHeight: height !== undefined ? height - theme.spacing.unit * 24 : undefined,
      }
    }
    var regstr = Object.keys(LANGUAGE_SHORT_CODE).map(key => LANGUAGE_SHORT_CODE[key]).join('|')
    return (
      <div className={classes.root} style={styles.root}>
        <Grid className={classes.gridContainer} container>
          <Grid item md={12} sm={12} xs={12}>
            <div className={classes.content} style={styles.content}>
              <Switch>
                <Route exact path={`/play/:language(${regstr})/play`} component={PlayPage} />
                <Route path={`/play/:language(${regstr})/vs`} component={VSPage} />
                <Route exact path={`/play/:language(${regstr})/puzzle`} component={PuzzlePage} />
                <Route path={`/play/:language(${regstr})/puzzle/nyanko`} component={NyankoPuzzlePage} />
                <Route path={`/play/:language(${regstr})/freeboard`} component={FreeBoardPage} />
                <Route exact path='/play' component={PlayPage} />
                <Route path='/play/vs' component={VSPage} />
                <Route exact path='/play/puzzle' component={PuzzlePage} />
                <Route path='/play/puzzle/nyanko' component={NyankoPuzzlePage} />
                <Route path='/play/freeboard' component={FreeBoardPage} />
              </Switch>
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className={classes.sideContent}>
              <UserCard/>
              <Switch>
                <Route path={`/play/:language(${regstr})/puzzle/nyanko`} component={NyankoPuzzleScoreCard} />
                <Route path='/play/puzzle/nyanko' component={NyankoPuzzleScoreCard} />
              </Switch>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(PlayRouting))
